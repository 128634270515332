
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexATi02OzR1srhRiZtVWOjZs9rqBOvbg7DRQlEE6kiDHoMeta } from "/home/runner/work/retail-navigator/retail-navigator/app/pages/index.vue?macro=true";
import { default as loginmP1_457vE2zWgSCfsxsDnOiEaQqWVuyTUgjPQ4Z00d5DUMeta } from "/home/runner/work/retail-navigator/retail-navigator/app/pages/login.vue?macro=true";
import { default as searchXZ3Hj4PgIDDD3py_JJrhpR2pkow9dcrTavcuucDaiIkMeta } from "/home/runner/work/retail-navigator/retail-navigator/app/pages/search.vue?macro=true";
import { default as confirm5L3f3dZJVDh4kBVxoxhZnzJbpq2bfa5aaP1M_pX83KoMeta } from "/home/runner/work/retail-navigator/retail-navigator/app/pages/confirm.vue?macro=true";
import { default as my_45files0eRkVMD6v_9kEcFgUt_3dQ_vqv4bZcGFdsXePvwovFUMeta } from "/home/runner/work/retail-navigator/retail-navigator/app/pages/my-files.vue?macro=true";
import { default as downloadsqbrTzJIpMeIyirj6LVkuW9V4inFT8ltiA0OFqINarPcMeta } from "/home/runner/work/retail-navigator/retail-navigator/app/pages/downloads.vue?macro=true";
import { default as index4EeuouhGBYiYz9tDCU7r1Jf4dwJGb1UTnMkAGPCFtBAMeta } from "/home/runner/work/retail-navigator/retail-navigator/app/pages/users/index.vue?macro=true";
import { default as _91uuid_93avaUq7wJ4HfuxhYJvPHFclDl9OKxB8Yq4O7Y0uGiBIQMeta } from "/home/runner/work/retail-navigator/retail-navigator/app/pages/users/[uuid].vue?macro=true";
import { default as _91slug_93ZpdpWN5uZWu6g5dT58ReeoOvHvaa90lMS5xkQuAwnKMMeta } from "/home/runner/work/retail-navigator/retail-navigator/app/pages/assets/[slug].vue?macro=true";
import { default as uploady6EprLDEEEme13Uwmjx7sLtBn15eryzm_45PuH1tblcCsMeta } from "/home/runner/work/retail-navigator/retail-navigator/app/pages/assets/upload.vue?macro=true";
import { default as _91id_93USXTeaj8mHskbzrt4IULh8XmiNWy_9i3Hqr7ljIvZ8kMeta } from "/home/runner/work/retail-navigator/retail-navigator/app/pages/products/[id].vue?macro=true";
import { default as indexWqL00eQX15uHBoWXtMZ9ALyL_zKNZbPb9j6nwFJ5XigMeta } from "/home/runner/work/retail-navigator/retail-navigator/app/pages/products/index.vue?macro=true";
import { default as _91id_93fZld_5n9AwLKQa3FRSRNJ5Vgm_f3NZrPzDRTYKtFiyAMeta } from "/home/runner/work/retail-navigator/retail-navigator/app/pages/retailers/[id].vue?macro=true";
import { default as index4aSFq2pDufCf7AS0UkZ_gHiuxlTVtqcGcaeTfhunj1AMeta } from "/home/runner/work/retail-navigator/retail-navigator/app/pages/settings/index.vue?macro=true";
import { default as indexa3nBDR5E2CBLTmnioOIIkb3ajUXq5QTHio6Ft3IL8KAMeta } from "/home/runner/work/retail-navigator/retail-navigator/app/pages/retailers/index.vue?macro=true";
import { default as _91id_93Uz7XLXF9zzFj_45od8nEpbmta_cF90JGIsAK0zrBTJlQ0Meta } from "/home/runner/work/retail-navigator/retail-navigator/app/pages/assets/edit/[id].vue?macro=true";
import { default as email_45statisticsktZfzvSu1S9R2nf8iBTv1jruwscT02v2sfJ_45je1V0nsMeta } from "/home/runner/work/retail-navigator/retail-navigator/app/pages/email-statistics.vue?macro=true";
import { default as categories2iI0awe9u3Bdjyc4_455EjGzULN5UWds_459Umwi6isLMzYMeta } from "/home/runner/work/retail-navigator/retail-navigator/app/pages/products/categories.vue?macro=true";
import { default as indexWqsZnOLNiPFmfVIFGLuRyDmXUJZDOX2KPChghUh00A4Meta } from "/home/runner/work/retail-navigator/retail-navigator/app/pages/assets/categories/index.vue?macro=true";
import { default as _91slug_93LIfS04_2VgfJ_45G0lCz0WHEqGsUz9du4FXawPr096i6AMeta } from "/home/runner/work/retail-navigator/retail-navigator/app/pages/assets/categories/[slug].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/retail-navigator/retail-navigator/app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginmP1_457vE2zWgSCfsxsDnOiEaQqWVuyTUgjPQ4Z00d5DUMeta || {},
    component: () => import("/home/runner/work/retail-navigator/retail-navigator/app/pages/login.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/home/runner/work/retail-navigator/retail-navigator/app/pages/search.vue")
  },
  {
    name: "confirm",
    path: "/confirm",
    meta: confirm5L3f3dZJVDh4kBVxoxhZnzJbpq2bfa5aaP1M_pX83KoMeta || {},
    component: () => import("/home/runner/work/retail-navigator/retail-navigator/app/pages/confirm.vue")
  },
  {
    name: "my-files",
    path: "/my-files",
    component: () => import("/home/runner/work/retail-navigator/retail-navigator/app/pages/my-files.vue")
  },
  {
    name: "downloads",
    path: "/downloads",
    component: () => import("/home/runner/work/retail-navigator/retail-navigator/app/pages/downloads.vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/home/runner/work/retail-navigator/retail-navigator/app/pages/users/index.vue")
  },
  {
    name: "users-uuid",
    path: "/users/:uuid()",
    component: () => import("/home/runner/work/retail-navigator/retail-navigator/app/pages/users/[uuid].vue")
  },
  {
    name: "assets-slug",
    path: "/assets/:slug()",
    meta: _91slug_93ZpdpWN5uZWu6g5dT58ReeoOvHvaa90lMS5xkQuAwnKMMeta || {},
    component: () => import("/home/runner/work/retail-navigator/retail-navigator/app/pages/assets/[slug].vue")
  },
  {
    name: "assets-upload",
    path: "/assets/upload",
    component: () => import("/home/runner/work/retail-navigator/retail-navigator/app/pages/assets/upload.vue")
  },
  {
    name: "products-id",
    path: "/products/:id()",
    component: () => import("/home/runner/work/retail-navigator/retail-navigator/app/pages/products/[id].vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/home/runner/work/retail-navigator/retail-navigator/app/pages/products/index.vue")
  },
  {
    name: "retailers-id",
    path: "/retailers/:id()",
    component: () => import("/home/runner/work/retail-navigator/retail-navigator/app/pages/retailers/[id].vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/home/runner/work/retail-navigator/retail-navigator/app/pages/settings/index.vue")
  },
  {
    name: "retailers",
    path: "/retailers",
    component: () => import("/home/runner/work/retail-navigator/retail-navigator/app/pages/retailers/index.vue")
  },
  {
    name: "assets-edit-id",
    path: "/assets/edit/:id()",
    component: () => import("/home/runner/work/retail-navigator/retail-navigator/app/pages/assets/edit/[id].vue")
  },
  {
    name: "email-statistics",
    path: "/email-statistics",
    component: () => import("/home/runner/work/retail-navigator/retail-navigator/app/pages/email-statistics.vue")
  },
  {
    name: "products-categories",
    path: "/products/categories",
    component: () => import("/home/runner/work/retail-navigator/retail-navigator/app/pages/products/categories.vue")
  },
  {
    name: "assets-categories",
    path: "/assets/categories",
    component: () => import("/home/runner/work/retail-navigator/retail-navigator/app/pages/assets/categories/index.vue")
  },
  {
    name: "assets-categories-slug",
    path: "/assets/categories/:slug()",
    component: () => import("/home/runner/work/retail-navigator/retail-navigator/app/pages/assets/categories/[slug].vue")
  }
]