import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/home/runner/work/retail-navigator/retail-navigator/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/home/runner/work/retail-navigator/retail-navigator/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/home/runner/work/retail-navigator/retail-navigator/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_daf8JLQYgNg5Oio6i3szlN9gBfCuQpCD32G5_g4EIgQ from "/home/runner/work/retail-navigator/retail-navigator/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import _01_rn_auth_rccSWq3xMcEJLRNy_oiJhL47v2yi5m1S821Ox9mLOr0 from "/home/runner/work/retail-navigator/retail-navigator/app/plugins/01.rn-auth.ts";
import _02_notification_subscriber_client_O4TbLiUbDBW_uRbGME3G2MlnXVaOrqruq8Ur4owGEew from "/home/runner/work/retail-navigator/retail-navigator/app/plugins/02.notification-subscriber.client.ts";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/home/runner/work/retail-navigator/retail-navigator/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/home/runner/work/retail-navigator/retail-navigator/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/home/runner/work/retail-navigator/retail-navigator/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/home/runner/work/retail-navigator/retail-navigator/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/home/runner/work/retail-navigator/retail-navigator/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/home/runner/work/retail-navigator/retail-navigator/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_g1RyjnFCC2XCqS3NLpv7yngCZ5AzjpmT7UJr_dW3BbY from "/home/runner/work/retail-navigator/retail-navigator/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_JhH8M1KzF3pQyhcHsoNTBLd8tKGet6zo2zTBVDe7nK4 from "/home/runner/work/retail-navigator/retail-navigator/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_E7kSti5pGZ28QhUUurq6gGRU3l65WuXO_KJC3GQgzFo from "/home/runner/work/retail-navigator/retail-navigator/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg from "/home/runner/work/retail-navigator/retail-navigator/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/home/runner/work/retail-navigator/retail-navigator/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import auth_redirect_HUzDltIkO2fpUO5JHjqMueRAOThb4ANNc2aslGKOTOY from "/home/runner/work/retail-navigator/retail-navigator/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import _03_tour_llsoJJVEmfIJYifv5ykFTB5Bm39871PQsIrS1I2MgN0 from "/home/runner/work/retail-navigator/retail-navigator/app/plugins/03.tour.ts";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  supabase_client_daf8JLQYgNg5Oio6i3szlN9gBfCuQpCD32G5_g4EIgQ,
  _01_rn_auth_rccSWq3xMcEJLRNy_oiJhL47v2yi5m1S821Ox9mLOr0,
  _02_notification_subscriber_client_O4TbLiUbDBW_uRbGME3G2MlnXVaOrqruq8Ur4owGEew,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  slideovers_g1RyjnFCC2XCqS3NLpv7yngCZ5AzjpmT7UJr_dW3BbY,
  modals_JhH8M1KzF3pQyhcHsoNTBLd8tKGet6zo2zTBVDe7nK4,
  colors_E7kSti5pGZ28QhUUurq6gGRU3l65WuXO_KJC3GQgzFo,
  plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  auth_redirect_HUzDltIkO2fpUO5JHjqMueRAOThb4ANNc2aslGKOTOY,
  _03_tour_llsoJJVEmfIJYifv5ykFTB5Bm39871PQsIrS1I2MgN0
]