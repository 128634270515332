import { defineComponent as ft, ref as ct, computed as M, onMounted as vt, getCurrentInstance as Rt, onBeforeUnmount as Ct, resolveComponent as Lt, openBlock as F, createElementBlock as I, renderSlot as Se, createBlock as Bt, createCommentVNode as J, onUnmounted as $t, normalizeClass as Je, unref as Ze, createElementVNode as Be, toDisplayString as re, withModifiers as Oe } from "vue";
const et = {
  onStart: () => {
  },
  onPreviousStep: () => {
  },
  onNextStep: () => {
  },
  onStop: () => {
  },
  onSkip: () => {
  },
  onFinish: () => {
  }
}, Ae = {
  highlight: !1,
  labels: {
    buttonSkip: "Skip tour",
    buttonPrevious: "Previous",
    buttonNext: "Next",
    buttonStop: "Finish"
  },
  enabledButtons: {
    buttonSkip: !0,
    buttonPrevious: !0,
    buttonNext: !0,
    buttonStop: !0
  },
  startTimeout: 0,
  useKeyboardNavigation: !0,
  enabledNavigationKeys: {
    ESCAPE: !0,
    ARROW_RIGHT: !0,
    ARROW_LEFT: !0
  },
  stopOnTargetNotFound: !0,
  debug: !1
}, W = {
  CLASSES: {
    ACTIVE: "v-tour--active",
    TARGET_HIGHLIGHTED: "v-tour__target--highlighted",
    TARGET_RELATIVE: "v-tour__target--relative"
  },
  TRANSITION: "box-shadow 0s ease-in-out 0s",
  useKeyboardNavigation: !0,
  startTimeout: 0,
  stopOnTargetNotFound: !0
}, Dt = {
  enableScrolling: !0,
  highlight: Ae.highlight,
  // By default use the global tour setting
  enabledButtons: Ae.enabledButtons,
  modifiers: [
    {
      name: "offset",
      options: {
        offset: () => [0, 8]
      }
    }
  ],
  placement: "bottom"
}, $e = {
  ARROW_RIGHT: 39,
  ARROW_LEFT: 37,
  ESCAPE: 27
}, Nt = { class: "v-tour" }, Ht = /* @__PURE__ */ ft({
  __name: "VTour",
  props: {
    name: {},
    steps: { default: () => [] },
    options: { default: () => Ae },
    callbacks: { default: () => et }
  },
  setup(t) {
    const e = t, r = ct(-1), n = M(() => ({
      ...Ae,
      ...e.options
    })), o = M(() => ({
      ...et,
      ...e.callbacks
    })), u = M(() => r.value > -1 && r.value < a.value), p = M(() => r.value === 0), s = M(() => r.value === e.steps.length - 1), a = M(() => e.steps.length), f = M(() => e.steps[r.value]), l = async (i) => {
      i = typeof i < "u" ? parseInt(String(i), 10) : 0;
      let v = e.steps[i], c = () => new Promise((m) => {
        setTimeout(() => {
          o.value.onStart(), r.value = i, m();
        }, n.value.startTimeout);
      });
      if (typeof v.before < "u")
        try {
          await v.before("start");
        } catch (m) {
          return Promise.reject(m);
        }
      return await c(), o.value.onStart(), r.value = i, Promise.resolve();
    }, h = async () => {
      let i = r.value - 1, v = () => new Promise((c) => {
        o.value.onPreviousStep(r.value), r.value = i, c();
      });
      if (i > -1) {
        let c = e.steps[i];
        if (typeof c.before < "u")
          try {
            await c.before("previous");
          } catch (m) {
            return Promise.reject(m);
          }
        await v();
      }
      return Promise.resolve();
    }, y = async () => {
      let i = r.value + 1, v = () => new Promise((c) => {
        o.value.onNextStep(r.value), r.value = i, c();
      });
      if (i < a.value && r.value !== -1) {
        let c = e.steps[i];
        if (typeof c.before < "u")
          try {
            await c.before("next");
          } catch (m) {
            return Promise.reject(m);
          }
        await v();
      }
      return Promise.resolve();
    }, d = () => {
      o.value.onStop(), document.body.classList.remove("v-tour--active"), r.value = -1;
    }, O = () => {
      o.value.onSkip(), d();
    }, g = () => {
      o.value.onFinish(), d();
    }, b = (i) => {
      switch (n.value.debug && console.log("[Vue Tour] A keyup event occured:", i), i.keyCode) {
        case $e.ARROW_RIGHT:
          w("ARROW_RIGHT") && y();
          break;
        case $e.ARROW_LEFT:
          w("ARROW_LEFT") && h();
          break;
        case $e.ESCAPE:
          w("ESCAPE") && d();
          break;
      }
    }, w = (i) => {
      const { enabledNavigationKeys: v } = n.value;
      return v.hasOwnProperty(i) ? v[i] : !0;
    };
    return vt(() => {
      const i = Rt();
      i && (i.appContext.config.globalProperties.$tours[e.name] = {
        step: f,
        start: l,
        isRunning: u,
        customOptions: n,
        currentStep: r,
        isFirst: p,
        isLast: s,
        previousStep: h,
        nextStep: y,
        stop: d,
        skip: O,
        finish: g,
        numberOfSteps: a
      }), n.value.useKeyboardNavigation && window.addEventListener("keyup", b);
    }), Ct(() => {
      n.value.useKeyboardNavigation && window.removeEventListener("keyup", b);
    }), (i, v) => {
      const c = Lt("v-step");
      return F(), I("div", Nt, [
        Se(i.$slots, "default", {
          currentStep: r.value,
          steps: i.steps,
          previousStep: h,
          nextStep: y,
          stop: d,
          skip: O,
          finish: g,
          isFirst: p.value,
          isLast: s.value,
          labels: n.value.labels,
          enabledButtons: n.value.enabledButtons,
          highlight: n.value.highlight,
          debug: n.value.debug
        }, () => [
          i.steps[r.value] ? (F(), Bt(c, {
            step: i.steps[r.value],
            key: r.value,
            "previous-step": h,
            "next-step": y,
            stop: d,
            skip: O,
            finish: g,
            "is-first": p.value,
            "is-last": s.value,
            labels: n.value.labels,
            "enabled-buttons": n.value.enabledButtons,
            highlight: n.value.highlight,
            "stop-on-fail": n.value.stopOnTargetNotFound,
            debug: n.value.debug,
            onTargetNotFound: v[0] || (v[0] = (m) => i.$emit("targetNotFound", m))
          }, null, 8, ["step", "is-first", "is-last", "labels", "enabled-buttons", "highlight", "stop-on-fail", "debug"])) : J("", !0)
        ])
      ]);
    };
  }
});
var R = "top", D = "bottom", N = "right", C = "left", He = "auto", de = [R, D, N, C], ne = "start", ce = "end", jt = "clippingParents", dt = "viewport", le = "popper", Ft = "reference", tt = /* @__PURE__ */ de.reduce(function(t, e) {
  return t.concat([e + "-" + ne, e + "-" + ce]);
}, []), ht = /* @__PURE__ */ [].concat(de, [He]).reduce(function(t, e) {
  return t.concat([e, e + "-" + ne, e + "-" + ce]);
}, []), It = "beforeRead", Vt = "read", _t = "afterRead", Wt = "beforeMain", Mt = "main", Gt = "afterMain", qt = "beforeWrite", Ut = "write", Yt = "afterWrite", Kt = [It, Vt, _t, Wt, Mt, Gt, qt, Ut, Yt];
function _(t) {
  return t ? (t.nodeName || "").toLowerCase() : null;
}
function B(t) {
  if (t == null)
    return window;
  if (t.toString() !== "[object Window]") {
    var e = t.ownerDocument;
    return e && e.defaultView || window;
  }
  return t;
}
function ee(t) {
  var e = B(t).Element;
  return t instanceof e || t instanceof Element;
}
function $(t) {
  var e = B(t).HTMLElement;
  return t instanceof e || t instanceof HTMLElement;
}
function je(t) {
  if (typeof ShadowRoot > "u")
    return !1;
  var e = B(t).ShadowRoot;
  return t instanceof e || t instanceof ShadowRoot;
}
function Xt(t) {
  var e = t.state;
  Object.keys(e.elements).forEach(function(r) {
    var n = e.styles[r] || {}, o = e.attributes[r] || {}, u = e.elements[r];
    !$(u) || !_(u) || (Object.assign(u.style, n), Object.keys(o).forEach(function(p) {
      var s = o[p];
      s === !1 ? u.removeAttribute(p) : u.setAttribute(p, s === !0 ? "" : s);
    }));
  });
}
function zt(t) {
  var e = t.state, r = {
    popper: {
      position: e.options.strategy,
      left: "0",
      top: "0",
      margin: "0"
    },
    arrow: {
      position: "absolute"
    },
    reference: {}
  };
  return Object.assign(e.elements.popper.style, r.popper), e.styles = r, e.elements.arrow && Object.assign(e.elements.arrow.style, r.arrow), function() {
    Object.keys(e.elements).forEach(function(n) {
      var o = e.elements[n], u = e.attributes[n] || {}, p = Object.keys(e.styles.hasOwnProperty(n) ? e.styles[n] : r[n]), s = p.reduce(function(a, f) {
        return a[f] = "", a;
      }, {});
      !$(o) || !_(o) || (Object.assign(o.style, s), Object.keys(u).forEach(function(a) {
        o.removeAttribute(a);
      }));
    });
  };
}
const Qt = {
  name: "applyStyles",
  enabled: !0,
  phase: "write",
  fn: Xt,
  effect: zt,
  requires: ["computeStyles"]
};
function V(t) {
  return t.split("-")[0];
}
var Z = Math.max, Te = Math.min, oe = Math.round;
function De() {
  var t = navigator.userAgentData;
  return t != null && t.brands && Array.isArray(t.brands) ? t.brands.map(function(e) {
    return e.brand + "/" + e.version;
  }).join(" ") : navigator.userAgent;
}
function mt() {
  return !/^((?!chrome|android).)*safari/i.test(De());
}
function ae(t, e, r) {
  e === void 0 && (e = !1), r === void 0 && (r = !1);
  var n = t.getBoundingClientRect(), o = 1, u = 1;
  e && $(t) && (o = t.offsetWidth > 0 && oe(n.width) / t.offsetWidth || 1, u = t.offsetHeight > 0 && oe(n.height) / t.offsetHeight || 1);
  var p = ee(t) ? B(t) : window, s = p.visualViewport, a = !mt() && r, f = (n.left + (a && s ? s.offsetLeft : 0)) / o, l = (n.top + (a && s ? s.offsetTop : 0)) / u, h = n.width / o, y = n.height / u;
  return {
    width: h,
    height: y,
    top: l,
    right: f + h,
    bottom: l + y,
    left: f,
    x: f,
    y: l
  };
}
function Fe(t) {
  var e = ae(t), r = t.offsetWidth, n = t.offsetHeight;
  return Math.abs(e.width - r) <= 1 && (r = e.width), Math.abs(e.height - n) <= 1 && (n = e.height), {
    x: t.offsetLeft,
    y: t.offsetTop,
    width: r,
    height: n
  };
}
function gt(t, e) {
  var r = e.getRootNode && e.getRootNode();
  if (t.contains(e))
    return !0;
  if (r && je(r)) {
    var n = e;
    do {
      if (n && t.isSameNode(n))
        return !0;
      n = n.parentNode || n.host;
    } while (n);
  }
  return !1;
}
function G(t) {
  return B(t).getComputedStyle(t);
}
function Jt(t) {
  return ["table", "td", "th"].indexOf(_(t)) >= 0;
}
function U(t) {
  return ((ee(t) ? t.ownerDocument : (
    // $FlowFixMe[prop-missing]
    t.document
  )) || window.document).documentElement;
}
function ke(t) {
  return _(t) === "html" ? t : (
    // this is a quicker (but less type safe) way to save quite some bytes from the bundle
    // $FlowFixMe[incompatible-return]
    // $FlowFixMe[prop-missing]
    t.assignedSlot || // step into the shadow DOM of the parent of a slotted node
    t.parentNode || // DOM Element detected
    (je(t) ? t.host : null) || // ShadowRoot detected
    // $FlowFixMe[incompatible-call]: HTMLElement is a Node
    U(t)
  );
}
function rt(t) {
  return !$(t) || // https://github.com/popperjs/popper-core/issues/837
  G(t).position === "fixed" ? null : t.offsetParent;
}
function Zt(t) {
  var e = /firefox/i.test(De()), r = /Trident/i.test(De());
  if (r && $(t)) {
    var n = G(t);
    if (n.position === "fixed")
      return null;
  }
  var o = ke(t);
  for (je(o) && (o = o.host); $(o) && ["html", "body"].indexOf(_(o)) < 0; ) {
    var u = G(o);
    if (u.transform !== "none" || u.perspective !== "none" || u.contain === "paint" || ["transform", "perspective"].indexOf(u.willChange) !== -1 || e && u.willChange === "filter" || e && u.filter && u.filter !== "none")
      return o;
    o = o.parentNode;
  }
  return null;
}
function he(t) {
  for (var e = B(t), r = rt(t); r && Jt(r) && G(r).position === "static"; )
    r = rt(r);
  return r && (_(r) === "html" || _(r) === "body" && G(r).position === "static") ? e : r || Zt(t) || e;
}
function Ie(t) {
  return ["top", "bottom"].indexOf(t) >= 0 ? "x" : "y";
}
function pe(t, e, r) {
  return Z(t, Te(e, r));
}
function er(t, e, r) {
  var n = pe(t, e, r);
  return n > r ? r : n;
}
function bt() {
  return {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  };
}
function yt(t) {
  return Object.assign({}, bt(), t);
}
function wt(t, e) {
  return e.reduce(function(r, n) {
    return r[n] = t, r;
  }, {});
}
var tr = function(e, r) {
  return e = typeof e == "function" ? e(Object.assign({}, r.rects, {
    placement: r.placement
  })) : e, yt(typeof e != "number" ? e : wt(e, de));
};
function rr(t) {
  var e, r = t.state, n = t.name, o = t.options, u = r.elements.arrow, p = r.modifiersData.popperOffsets, s = V(r.placement), a = Ie(s), f = [C, N].indexOf(s) >= 0, l = f ? "height" : "width";
  if (!(!u || !p)) {
    var h = tr(o.padding, r), y = Fe(u), d = a === "y" ? R : C, O = a === "y" ? D : N, g = r.rects.reference[l] + r.rects.reference[a] - p[a] - r.rects.popper[l], b = p[a] - r.rects.reference[a], w = he(u), i = w ? a === "y" ? w.clientHeight || 0 : w.clientWidth || 0 : 0, v = g / 2 - b / 2, c = h[d], m = i - y[l] - h[O], x = i / 2 - y[l] / 2 + v, S = pe(c, x, m), E = a;
    r.modifiersData[n] = (e = {}, e[E] = S, e.centerOffset = S - x, e);
  }
}
function nr(t) {
  var e = t.state, r = t.options, n = r.element, o = n === void 0 ? "[data-popper-arrow]" : n;
  o != null && (typeof o == "string" && (o = e.elements.popper.querySelector(o), !o) || gt(e.elements.popper, o) && (e.elements.arrow = o));
}
const or = {
  name: "arrow",
  enabled: !0,
  phase: "main",
  fn: rr,
  effect: nr,
  requires: ["popperOffsets"],
  requiresIfExists: ["preventOverflow"]
};
function ie(t) {
  return t.split("-")[1];
}
var ar = {
  top: "auto",
  right: "auto",
  bottom: "auto",
  left: "auto"
};
function ir(t, e) {
  var r = t.x, n = t.y, o = e.devicePixelRatio || 1;
  return {
    x: oe(r * o) / o || 0,
    y: oe(n * o) / o || 0
  };
}
function nt(t) {
  var e, r = t.popper, n = t.popperRect, o = t.placement, u = t.variation, p = t.offsets, s = t.position, a = t.gpuAcceleration, f = t.adaptive, l = t.roundOffsets, h = t.isFixed, y = p.x, d = y === void 0 ? 0 : y, O = p.y, g = O === void 0 ? 0 : O, b = typeof l == "function" ? l({
    x: d,
    y: g
  }) : {
    x: d,
    y: g
  };
  d = b.x, g = b.y;
  var w = p.hasOwnProperty("x"), i = p.hasOwnProperty("y"), v = C, c = R, m = window;
  if (f) {
    var x = he(r), S = "clientHeight", E = "clientWidth";
    if (x === B(r) && (x = U(r), G(x).position !== "static" && s === "absolute" && (S = "scrollHeight", E = "scrollWidth")), x = x, o === R || (o === C || o === N) && u === ce) {
      c = D;
      var T = h && x === m && m.visualViewport ? m.visualViewport.height : (
        // $FlowFixMe[prop-missing]
        x[S]
      );
      g -= T - n.height, g *= a ? 1 : -1;
    }
    if (o === C || (o === R || o === D) && u === ce) {
      v = N;
      var A = h && x === m && m.visualViewport ? m.visualViewport.width : (
        // $FlowFixMe[prop-missing]
        x[E]
      );
      d -= A - n.width, d *= a ? 1 : -1;
    }
  }
  var k = Object.assign({
    position: s
  }, f && ar), H = l === !0 ? ir({
    x: d,
    y: g
  }, B(r)) : {
    x: d,
    y: g
  };
  if (d = H.x, g = H.y, a) {
    var P;
    return Object.assign({}, k, (P = {}, P[c] = i ? "0" : "", P[v] = w ? "0" : "", P.transform = (m.devicePixelRatio || 1) <= 1 ? "translate(" + d + "px, " + g + "px)" : "translate3d(" + d + "px, " + g + "px, 0)", P));
  }
  return Object.assign({}, k, (e = {}, e[c] = i ? g + "px" : "", e[v] = w ? d + "px" : "", e.transform = "", e));
}
function sr(t) {
  var e = t.state, r = t.options, n = r.gpuAcceleration, o = n === void 0 ? !0 : n, u = r.adaptive, p = u === void 0 ? !0 : u, s = r.roundOffsets, a = s === void 0 ? !0 : s, f = {
    placement: V(e.placement),
    variation: ie(e.placement),
    popper: e.elements.popper,
    popperRect: e.rects.popper,
    gpuAcceleration: o,
    isFixed: e.options.strategy === "fixed"
  };
  e.modifiersData.popperOffsets != null && (e.styles.popper = Object.assign({}, e.styles.popper, nt(Object.assign({}, f, {
    offsets: e.modifiersData.popperOffsets,
    position: e.options.strategy,
    adaptive: p,
    roundOffsets: a
  })))), e.modifiersData.arrow != null && (e.styles.arrow = Object.assign({}, e.styles.arrow, nt(Object.assign({}, f, {
    offsets: e.modifiersData.arrow,
    position: "absolute",
    adaptive: !1,
    roundOffsets: a
  })))), e.attributes.popper = Object.assign({}, e.attributes.popper, {
    "data-popper-placement": e.placement
  });
}
const ur = {
  name: "computeStyles",
  enabled: !0,
  phase: "beforeWrite",
  fn: sr,
  data: {}
};
var xe = {
  passive: !0
};
function lr(t) {
  var e = t.state, r = t.instance, n = t.options, o = n.scroll, u = o === void 0 ? !0 : o, p = n.resize, s = p === void 0 ? !0 : p, a = B(e.elements.popper), f = [].concat(e.scrollParents.reference, e.scrollParents.popper);
  return u && f.forEach(function(l) {
    l.addEventListener("scroll", r.update, xe);
  }), s && a.addEventListener("resize", r.update, xe), function() {
    u && f.forEach(function(l) {
      l.removeEventListener("scroll", r.update, xe);
    }), s && a.removeEventListener("resize", r.update, xe);
  };
}
const pr = {
  name: "eventListeners",
  enabled: !0,
  phase: "write",
  fn: function() {
  },
  effect: lr,
  data: {}
};
var fr = {
  left: "right",
  right: "left",
  bottom: "top",
  top: "bottom"
};
function Ee(t) {
  return t.replace(/left|right|bottom|top/g, function(e) {
    return fr[e];
  });
}
var cr = {
  start: "end",
  end: "start"
};
function ot(t) {
  return t.replace(/start|end/g, function(e) {
    return cr[e];
  });
}
function Ve(t) {
  var e = B(t), r = e.pageXOffset, n = e.pageYOffset;
  return {
    scrollLeft: r,
    scrollTop: n
  };
}
function _e(t) {
  return ae(U(t)).left + Ve(t).scrollLeft;
}
function vr(t, e) {
  var r = B(t), n = U(t), o = r.visualViewport, u = n.clientWidth, p = n.clientHeight, s = 0, a = 0;
  if (o) {
    u = o.width, p = o.height;
    var f = mt();
    (f || !f && e === "fixed") && (s = o.offsetLeft, a = o.offsetTop);
  }
  return {
    width: u,
    height: p,
    x: s + _e(t),
    y: a
  };
}
function dr(t) {
  var e, r = U(t), n = Ve(t), o = (e = t.ownerDocument) == null ? void 0 : e.body, u = Z(r.scrollWidth, r.clientWidth, o ? o.scrollWidth : 0, o ? o.clientWidth : 0), p = Z(r.scrollHeight, r.clientHeight, o ? o.scrollHeight : 0, o ? o.clientHeight : 0), s = -n.scrollLeft + _e(t), a = -n.scrollTop;
  return G(o || r).direction === "rtl" && (s += Z(r.clientWidth, o ? o.clientWidth : 0) - u), {
    width: u,
    height: p,
    x: s,
    y: a
  };
}
function We(t) {
  var e = G(t), r = e.overflow, n = e.overflowX, o = e.overflowY;
  return /auto|scroll|overlay|hidden/.test(r + o + n);
}
function Ot(t) {
  return ["html", "body", "#document"].indexOf(_(t)) >= 0 ? t.ownerDocument.body : $(t) && We(t) ? t : Ot(ke(t));
}
function fe(t, e) {
  var r;
  e === void 0 && (e = []);
  var n = Ot(t), o = n === ((r = t.ownerDocument) == null ? void 0 : r.body), u = B(n), p = o ? [u].concat(u.visualViewport || [], We(n) ? n : []) : n, s = e.concat(p);
  return o ? s : (
    // $FlowFixMe[incompatible-call]: isBody tells us target will be an HTMLElement here
    s.concat(fe(ke(p)))
  );
}
function Ne(t) {
  return Object.assign({}, t, {
    left: t.x,
    top: t.y,
    right: t.x + t.width,
    bottom: t.y + t.height
  });
}
function hr(t, e) {
  var r = ae(t, !1, e === "fixed");
  return r.top = r.top + t.clientTop, r.left = r.left + t.clientLeft, r.bottom = r.top + t.clientHeight, r.right = r.left + t.clientWidth, r.width = t.clientWidth, r.height = t.clientHeight, r.x = r.left, r.y = r.top, r;
}
function at(t, e, r) {
  return e === dt ? Ne(vr(t, r)) : ee(e) ? hr(e, r) : Ne(dr(U(t)));
}
function mr(t) {
  var e = fe(ke(t)), r = ["absolute", "fixed"].indexOf(G(t).position) >= 0, n = r && $(t) ? he(t) : t;
  return ee(n) ? e.filter(function(o) {
    return ee(o) && gt(o, n) && _(o) !== "body";
  }) : [];
}
function gr(t, e, r, n) {
  var o = e === "clippingParents" ? mr(t) : [].concat(e), u = [].concat(o, [r]), p = u[0], s = u.reduce(function(a, f) {
    var l = at(t, f, n);
    return a.top = Z(l.top, a.top), a.right = Te(l.right, a.right), a.bottom = Te(l.bottom, a.bottom), a.left = Z(l.left, a.left), a;
  }, at(t, p, n));
  return s.width = s.right - s.left, s.height = s.bottom - s.top, s.x = s.left, s.y = s.top, s;
}
function xt(t) {
  var e = t.reference, r = t.element, n = t.placement, o = n ? V(n) : null, u = n ? ie(n) : null, p = e.x + e.width / 2 - r.width / 2, s = e.y + e.height / 2 - r.height / 2, a;
  switch (o) {
    case R:
      a = {
        x: p,
        y: e.y - r.height
      };
      break;
    case D:
      a = {
        x: p,
        y: e.y + e.height
      };
      break;
    case N:
      a = {
        x: e.x + e.width,
        y: s
      };
      break;
    case C:
      a = {
        x: e.x - r.width,
        y: s
      };
      break;
    default:
      a = {
        x: e.x,
        y: e.y
      };
  }
  var f = o ? Ie(o) : null;
  if (f != null) {
    var l = f === "y" ? "height" : "width";
    switch (u) {
      case ne:
        a[f] = a[f] - (e[l] / 2 - r[l] / 2);
        break;
      case ce:
        a[f] = a[f] + (e[l] / 2 - r[l] / 2);
        break;
    }
  }
  return a;
}
function ve(t, e) {
  e === void 0 && (e = {});
  var r = e, n = r.placement, o = n === void 0 ? t.placement : n, u = r.strategy, p = u === void 0 ? t.strategy : u, s = r.boundary, a = s === void 0 ? jt : s, f = r.rootBoundary, l = f === void 0 ? dt : f, h = r.elementContext, y = h === void 0 ? le : h, d = r.altBoundary, O = d === void 0 ? !1 : d, g = r.padding, b = g === void 0 ? 0 : g, w = yt(typeof b != "number" ? b : wt(b, de)), i = y === le ? Ft : le, v = t.rects.popper, c = t.elements[O ? i : y], m = gr(ee(c) ? c : c.contextElement || U(t.elements.popper), a, l, p), x = ae(t.elements.reference), S = xt({
    reference: x,
    element: v,
    strategy: "absolute",
    placement: o
  }), E = Ne(Object.assign({}, v, S)), T = y === le ? E : x, A = {
    top: m.top - T.top + w.top,
    bottom: T.bottom - m.bottom + w.bottom,
    left: m.left - T.left + w.left,
    right: T.right - m.right + w.right
  }, k = t.modifiersData.offset;
  if (y === le && k) {
    var H = k[o];
    Object.keys(A).forEach(function(P) {
      var Y = [N, D].indexOf(P) >= 0 ? 1 : -1, K = [R, D].indexOf(P) >= 0 ? "y" : "x";
      A[P] += H[K] * Y;
    });
  }
  return A;
}
function br(t, e) {
  e === void 0 && (e = {});
  var r = e, n = r.placement, o = r.boundary, u = r.rootBoundary, p = r.padding, s = r.flipVariations, a = r.allowedAutoPlacements, f = a === void 0 ? ht : a, l = ie(n), h = l ? s ? tt : tt.filter(function(O) {
    return ie(O) === l;
  }) : de, y = h.filter(function(O) {
    return f.indexOf(O) >= 0;
  });
  y.length === 0 && (y = h);
  var d = y.reduce(function(O, g) {
    return O[g] = ve(t, {
      placement: g,
      boundary: o,
      rootBoundary: u,
      padding: p
    })[V(g)], O;
  }, {});
  return Object.keys(d).sort(function(O, g) {
    return d[O] - d[g];
  });
}
function yr(t) {
  if (V(t) === He)
    return [];
  var e = Ee(t);
  return [ot(t), e, ot(e)];
}
function wr(t) {
  var e = t.state, r = t.options, n = t.name;
  if (!e.modifiersData[n]._skip) {
    for (var o = r.mainAxis, u = o === void 0 ? !0 : o, p = r.altAxis, s = p === void 0 ? !0 : p, a = r.fallbackPlacements, f = r.padding, l = r.boundary, h = r.rootBoundary, y = r.altBoundary, d = r.flipVariations, O = d === void 0 ? !0 : d, g = r.allowedAutoPlacements, b = e.options.placement, w = V(b), i = w === b, v = a || (i || !O ? [Ee(b)] : yr(b)), c = [b].concat(v).reduce(function(te, q) {
      return te.concat(V(q) === He ? br(e, {
        placement: q,
        boundary: l,
        rootBoundary: h,
        padding: f,
        flipVariations: O,
        allowedAutoPlacements: g
      }) : q);
    }, []), m = e.rects.reference, x = e.rects.popper, S = /* @__PURE__ */ new Map(), E = !0, T = c[0], A = 0; A < c.length; A++) {
      var k = c[A], H = V(k), P = ie(k) === ne, Y = [R, D].indexOf(H) >= 0, K = Y ? "width" : "height", L = ve(e, {
        placement: k,
        boundary: l,
        rootBoundary: h,
        altBoundary: y,
        padding: f
      }), j = Y ? P ? N : C : P ? D : R;
      m[K] > x[K] && (j = Ee(j));
      var me = Ee(j), X = [];
      if (u && X.push(L[H] <= 0), s && X.push(L[j] <= 0, L[me] <= 0), X.every(function(te) {
        return te;
      })) {
        T = k, E = !1;
        break;
      }
      S.set(k, X);
    }
    if (E)
      for (var ge = O ? 3 : 1, Pe = function(q) {
        var ue = c.find(function(ye) {
          var z = S.get(ye);
          if (z)
            return z.slice(0, q).every(function(Re) {
              return Re;
            });
        });
        if (ue)
          return T = ue, "break";
      }, se = ge; se > 0; se--) {
        var be = Pe(se);
        if (be === "break")
          break;
      }
    e.placement !== T && (e.modifiersData[n]._skip = !0, e.placement = T, e.reset = !0);
  }
}
const Or = {
  name: "flip",
  enabled: !0,
  phase: "main",
  fn: wr,
  requiresIfExists: ["offset"],
  data: {
    _skip: !1
  }
};
function it(t, e, r) {
  return r === void 0 && (r = {
    x: 0,
    y: 0
  }), {
    top: t.top - e.height - r.y,
    right: t.right - e.width + r.x,
    bottom: t.bottom - e.height + r.y,
    left: t.left - e.width - r.x
  };
}
function st(t) {
  return [R, N, D, C].some(function(e) {
    return t[e] >= 0;
  });
}
function xr(t) {
  var e = t.state, r = t.name, n = e.rects.reference, o = e.rects.popper, u = e.modifiersData.preventOverflow, p = ve(e, {
    elementContext: "reference"
  }), s = ve(e, {
    altBoundary: !0
  }), a = it(p, n), f = it(s, o, u), l = st(a), h = st(f);
  e.modifiersData[r] = {
    referenceClippingOffsets: a,
    popperEscapeOffsets: f,
    isReferenceHidden: l,
    hasPopperEscaped: h
  }, e.attributes.popper = Object.assign({}, e.attributes.popper, {
    "data-popper-reference-hidden": l,
    "data-popper-escaped": h
  });
}
const Sr = {
  name: "hide",
  enabled: !0,
  phase: "main",
  requiresIfExists: ["preventOverflow"],
  fn: xr
};
function Er(t, e, r) {
  var n = V(t), o = [C, R].indexOf(n) >= 0 ? -1 : 1, u = typeof r == "function" ? r(Object.assign({}, e, {
    placement: t
  })) : r, p = u[0], s = u[1];
  return p = p || 0, s = (s || 0) * o, [C, N].indexOf(n) >= 0 ? {
    x: s,
    y: p
  } : {
    x: p,
    y: s
  };
}
function Ar(t) {
  var e = t.state, r = t.options, n = t.name, o = r.offset, u = o === void 0 ? [0, 0] : o, p = ht.reduce(function(l, h) {
    return l[h] = Er(h, e.rects, u), l;
  }, {}), s = p[e.placement], a = s.x, f = s.y;
  e.modifiersData.popperOffsets != null && (e.modifiersData.popperOffsets.x += a, e.modifiersData.popperOffsets.y += f), e.modifiersData[n] = p;
}
const Tr = {
  name: "offset",
  enabled: !0,
  phase: "main",
  requires: ["popperOffsets"],
  fn: Ar
};
function kr(t) {
  var e = t.state, r = t.name;
  e.modifiersData[r] = xt({
    reference: e.rects.reference,
    element: e.rects.popper,
    strategy: "absolute",
    placement: e.placement
  });
}
const Pr = {
  name: "popperOffsets",
  enabled: !0,
  phase: "read",
  fn: kr,
  data: {}
};
function Rr(t) {
  return t === "x" ? "y" : "x";
}
function Cr(t) {
  var e = t.state, r = t.options, n = t.name, o = r.mainAxis, u = o === void 0 ? !0 : o, p = r.altAxis, s = p === void 0 ? !1 : p, a = r.boundary, f = r.rootBoundary, l = r.altBoundary, h = r.padding, y = r.tether, d = y === void 0 ? !0 : y, O = r.tetherOffset, g = O === void 0 ? 0 : O, b = ve(e, {
    boundary: a,
    rootBoundary: f,
    padding: h,
    altBoundary: l
  }), w = V(e.placement), i = ie(e.placement), v = !i, c = Ie(w), m = Rr(c), x = e.modifiersData.popperOffsets, S = e.rects.reference, E = e.rects.popper, T = typeof g == "function" ? g(Object.assign({}, e.rects, {
    placement: e.placement
  })) : g, A = typeof T == "number" ? {
    mainAxis: T,
    altAxis: T
  } : Object.assign({
    mainAxis: 0,
    altAxis: 0
  }, T), k = e.modifiersData.offset ? e.modifiersData.offset[e.placement] : null, H = {
    x: 0,
    y: 0
  };
  if (x) {
    if (u) {
      var P, Y = c === "y" ? R : C, K = c === "y" ? D : N, L = c === "y" ? "height" : "width", j = x[c], me = j + b[Y], X = j - b[K], ge = d ? -E[L] / 2 : 0, Pe = i === ne ? S[L] : E[L], se = i === ne ? -E[L] : -S[L], be = e.elements.arrow, te = d && be ? Fe(be) : {
        width: 0,
        height: 0
      }, q = e.modifiersData["arrow#persistent"] ? e.modifiersData["arrow#persistent"].padding : bt(), ue = q[Y], ye = q[K], z = pe(0, S[L], te[L]), Re = v ? S[L] / 2 - ge - z - ue - A.mainAxis : Pe - z - ue - A.mainAxis, St = v ? -S[L] / 2 + ge + z + ye + A.mainAxis : se + z + ye + A.mainAxis, Ce = e.elements.arrow && he(e.elements.arrow), Et = Ce ? c === "y" ? Ce.clientTop || 0 : Ce.clientLeft || 0 : 0, Me = (P = k == null ? void 0 : k[c]) != null ? P : 0, At = j + Re - Me - Et, Tt = j + St - Me, Ge = pe(d ? Te(me, At) : me, j, d ? Z(X, Tt) : X);
      x[c] = Ge, H[c] = Ge - j;
    }
    if (s) {
      var qe, kt = c === "x" ? R : C, Pt = c === "x" ? D : N, Q = x[m], we = m === "y" ? "height" : "width", Ue = Q + b[kt], Ye = Q - b[Pt], Le = [R, C].indexOf(w) !== -1, Ke = (qe = k == null ? void 0 : k[m]) != null ? qe : 0, Xe = Le ? Ue : Q - S[we] - E[we] - Ke + A.altAxis, ze = Le ? Q + S[we] + E[we] - Ke - A.altAxis : Ye, Qe = d && Le ? er(Xe, Q, ze) : pe(d ? Xe : Ue, Q, d ? ze : Ye);
      x[m] = Qe, H[m] = Qe - Q;
    }
    e.modifiersData[n] = H;
  }
}
const Lr = {
  name: "preventOverflow",
  enabled: !0,
  phase: "main",
  fn: Cr,
  requiresIfExists: ["offset"]
};
function Br(t) {
  return {
    scrollLeft: t.scrollLeft,
    scrollTop: t.scrollTop
  };
}
function $r(t) {
  return t === B(t) || !$(t) ? Ve(t) : Br(t);
}
function Dr(t) {
  var e = t.getBoundingClientRect(), r = oe(e.width) / t.offsetWidth || 1, n = oe(e.height) / t.offsetHeight || 1;
  return r !== 1 || n !== 1;
}
function Nr(t, e, r) {
  r === void 0 && (r = !1);
  var n = $(e), o = $(e) && Dr(e), u = U(e), p = ae(t, o, r), s = {
    scrollLeft: 0,
    scrollTop: 0
  }, a = {
    x: 0,
    y: 0
  };
  return (n || !n && !r) && ((_(e) !== "body" || // https://github.com/popperjs/popper-core/issues/1078
  We(u)) && (s = $r(e)), $(e) ? (a = ae(e, !0), a.x += e.clientLeft, a.y += e.clientTop) : u && (a.x = _e(u))), {
    x: p.left + s.scrollLeft - a.x,
    y: p.top + s.scrollTop - a.y,
    width: p.width,
    height: p.height
  };
}
function Hr(t) {
  var e = /* @__PURE__ */ new Map(), r = /* @__PURE__ */ new Set(), n = [];
  t.forEach(function(u) {
    e.set(u.name, u);
  });
  function o(u) {
    r.add(u.name);
    var p = [].concat(u.requires || [], u.requiresIfExists || []);
    p.forEach(function(s) {
      if (!r.has(s)) {
        var a = e.get(s);
        a && o(a);
      }
    }), n.push(u);
  }
  return t.forEach(function(u) {
    r.has(u.name) || o(u);
  }), n;
}
function jr(t) {
  var e = Hr(t);
  return Kt.reduce(function(r, n) {
    return r.concat(e.filter(function(o) {
      return o.phase === n;
    }));
  }, []);
}
function Fr(t) {
  var e;
  return function() {
    return e || (e = new Promise(function(r) {
      Promise.resolve().then(function() {
        e = void 0, r(t());
      });
    })), e;
  };
}
function Ir(t) {
  var e = t.reduce(function(r, n) {
    var o = r[n.name];
    return r[n.name] = o ? Object.assign({}, o, n, {
      options: Object.assign({}, o.options, n.options),
      data: Object.assign({}, o.data, n.data)
    }) : n, r;
  }, {});
  return Object.keys(e).map(function(r) {
    return e[r];
  });
}
var ut = {
  placement: "bottom",
  modifiers: [],
  strategy: "absolute"
};
function lt() {
  for (var t = arguments.length, e = new Array(t), r = 0; r < t; r++)
    e[r] = arguments[r];
  return !e.some(function(n) {
    return !(n && typeof n.getBoundingClientRect == "function");
  });
}
function Vr(t) {
  t === void 0 && (t = {});
  var e = t, r = e.defaultModifiers, n = r === void 0 ? [] : r, o = e.defaultOptions, u = o === void 0 ? ut : o;
  return function(s, a, f) {
    f === void 0 && (f = u);
    var l = {
      placement: "bottom",
      orderedModifiers: [],
      options: Object.assign({}, ut, u),
      modifiersData: {},
      elements: {
        reference: s,
        popper: a
      },
      attributes: {},
      styles: {}
    }, h = [], y = !1, d = {
      state: l,
      setOptions: function(w) {
        var i = typeof w == "function" ? w(l.options) : w;
        g(), l.options = Object.assign({}, u, l.options, i), l.scrollParents = {
          reference: ee(s) ? fe(s) : s.contextElement ? fe(s.contextElement) : [],
          popper: fe(a)
        };
        var v = jr(Ir([].concat(n, l.options.modifiers)));
        return l.orderedModifiers = v.filter(function(c) {
          return c.enabled;
        }), O(), d.update();
      },
      // Sync update – it will always be executed, even if not necessary. This
      // is useful for low frequency updates where sync behavior simplifies the
      // logic.
      // For high frequency updates (e.g. `resize` and `scroll` events), always
      // prefer the async Popper#update method
      forceUpdate: function() {
        if (!y) {
          var w = l.elements, i = w.reference, v = w.popper;
          if (lt(i, v)) {
            l.rects = {
              reference: Nr(i, he(v), l.options.strategy === "fixed"),
              popper: Fe(v)
            }, l.reset = !1, l.placement = l.options.placement, l.orderedModifiers.forEach(function(A) {
              return l.modifiersData[A.name] = Object.assign({}, A.data);
            });
            for (var c = 0; c < l.orderedModifiers.length; c++) {
              if (l.reset === !0) {
                l.reset = !1, c = -1;
                continue;
              }
              var m = l.orderedModifiers[c], x = m.fn, S = m.options, E = S === void 0 ? {} : S, T = m.name;
              typeof x == "function" && (l = x({
                state: l,
                options: E,
                name: T,
                instance: d
              }) || l);
            }
          }
        }
      },
      // Async and optimistically optimized update – it will not be executed if
      // not necessary (debounced to run at most once-per-tick)
      update: Fr(function() {
        return new Promise(function(b) {
          d.forceUpdate(), b(l);
        });
      }),
      destroy: function() {
        g(), y = !0;
      }
    };
    if (!lt(s, a))
      return d;
    d.setOptions(f).then(function(b) {
      !y && f.onFirstUpdate && f.onFirstUpdate(b);
    });
    function O() {
      l.orderedModifiers.forEach(function(b) {
        var w = b.name, i = b.options, v = i === void 0 ? {} : i, c = b.effect;
        if (typeof c == "function") {
          var m = c({
            state: l,
            name: w,
            instance: d,
            options: v
          }), x = function() {
          };
          h.push(m || x);
        }
      });
    }
    function g() {
      h.forEach(function(b) {
        return b();
      }), h = [];
    }
    return d;
  };
}
var _r = [pr, Pr, ur, Qt, Tr, Or, Lr, or, Sr], Wr = /* @__PURE__ */ Vr({
  defaultModifiers: _r
}), Mr = function(e, r, n, o) {
  return e /= o / 2, e < 1 ? n / 2 * e * e + r : (e--, -n / 2 * (e * (e - 2) - 1) + r);
}, pt = typeof Symbol == "function" && typeof Symbol.iterator == "symbol" ? function(t) {
  return typeof t;
} : function(t) {
  return t && typeof Symbol == "function" && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
}, Gr = function() {
  var e = void 0, r = void 0, n = void 0, o = void 0, u = void 0, p = void 0, s = void 0, a = void 0, f = void 0, l = void 0, h = void 0, y = void 0;
  function d() {
    return window.scrollY || window.pageYOffset;
  }
  function O(i) {
    return i.getBoundingClientRect().top + r;
  }
  function g(i) {
    f || (f = i), l = i - f, h = u(l, r, s, a), window.scrollTo(0, h), l < a ? window.requestAnimationFrame(g) : b();
  }
  function b() {
    window.scrollTo(0, r + s), e && p && (e.setAttribute("tabindex", "-1"), e.focus()), typeof y == "function" && y(), f = !1;
  }
  function w(i) {
    var v = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    switch (a = v.duration || 1e3, o = v.offset || 0, y = v.callback, u = v.easing || Mr, p = v.a11y || !1, r = d(), typeof i > "u" ? "undefined" : pt(i)) {
      case "number":
        e = void 0, p = !1, n = r + i;
        break;
      case "object":
        e = i, n = O(e);
        break;
      case "string":
        e = document.querySelector(i), n = O(e);
        break;
    }
    switch (s = n - r + o, pt(v.duration)) {
      case "number":
        a = v.duration;
        break;
      case "function":
        a = v.duration(s);
        break;
    }
    window.requestAnimationFrame(g);
  }
  return w;
}, qr = Gr();
const Ur = ["id"], Yr = {
  key: 0,
  class: "v-step__header"
}, Kr = ["innerHTML"], Xr = { class: "v-step__content" }, zr = ["innerHTML"], Qr = { key: 1 }, Jr = { class: "v-step__buttons" }, Zr = /* @__PURE__ */ ft({
  __name: "VStep",
  props: {
    step: {},
    highlight: { type: Boolean },
    enabledButtons: {},
    stopOnFail: { type: Boolean },
    stop: { type: Function },
    finish: { type: Function },
    skip: { type: Function },
    previousStep: { type: Function },
    nextStep: { type: Function },
    displayMask: { type: Boolean },
    isFirst: { type: Boolean },
    isLast: { type: Boolean },
    labels: {},
    debug: { type: Boolean }
  },
  emits: ["targetNotFound"],
  setup(t, { emit: e }) {
    const r = t, n = e, o = () => (r.skip || r.stop)(), u = () => (r.finish || r.stop)(), s = ((i) => {
      for (var v = 0, c = 0; c < i.length; c++)
        v = 31 * v + i.charCodeAt(c);
      return v & 4294967295;
    })(r.step.target), a = document.querySelector(r.step.target), f = M(() => ({
      ...Dt,
      highlight: r.highlight,
      enabledButtons: Object.assign({}, r.enabledButtons),
      ...r.step.params
      // Then use local step parameters if defined
    })), l = M(() => !r.step.target), h = ct(null), y = () => {
      if (r.debug && console.log("[Vue Tour] The target element " + r.step.target + ' of .v-step[id="' + s + '"] is:', a), !h.value) {
        r.debug && console.error("[Vue Tour] The target element " + r.step.target + ' of .v-step[id="' + s + '"] does not exist!');
        return;
      }
      l.value ? document.body.appendChild(h.value) : a ? (d(), g(), Wr(
        a,
        h.value,
        f.value
      )) : (r.debug && console.error("[Vue Tour] The target element " + r.step.target + ' of .v-step[id="' + s + '"] does not exist!'), n("targetNotFound", r.step), r.stopOnFail && r.stop());
    }, d = () => {
      if (a && f.value.enableScrolling)
        if (r.step.duration || r.step.offset) {
          let i = {
            duration: r.step.duration || 1e3,
            offset: r.step.offset || 0,
            callback: void 0,
            a11y: !1
          };
          qr(a, i);
        } else
          a.scrollIntoView({ behavior: "smooth" });
    }, O = () => (r.debug && console.log(`[Vue Tour] Highlight is ${f.value.highlight ? "enabled" : "disabled"} for .v-step[id="${s}"]`), f.value.highlight), g = () => {
      if (a && O()) {
        document.body.classList.add(W.CLASSES.ACTIVE);
        const i = window.getComputedStyle(a).getPropertyValue("transition");
        i !== "all 0s ease 0s" && (a.style.transition = `${i}, ${W.TRANSITION}`), a.classList.add(W.CLASSES.TARGET_HIGHLIGHTED), a.style.position || a.classList.add(W.CLASSES.TARGET_RELATIVE);
      } else
        document.body.classList.remove(W.CLASSES.ACTIVE);
    }, b = () => {
      if (a && O()) {
        const i = a.style.transition;
        a.classList.remove(W.CLASSES.TARGET_HIGHLIGHTED), a.classList.remove(W.CLASSES.TARGET_RELATIVE), i.includes(W.TRANSITION) && setTimeout(() => {
          a.style.transition = i.replace(`, ${W.TRANSITION}`, "");
        }, 0);
      }
    }, w = (i) => f.value.enabledButtons.hasOwnProperty(i) ? f.value.enabledButtons[i] : !0;
    return vt(y), $t(b), (i, v) => (F(), I("div", {
      class: Je([{ "v-step--sticky": l.value }, "v-step"]),
      id: "v-step-" + Ze(s),
      ref_key: "VStep",
      ref: h
    }, [
      Se(i.$slots, "header", {}, () => [
        i.step.header ? (F(), I("div", Yr, [
          i.step.header.title ? (F(), I("div", {
            key: 0,
            innerHTML: i.step.header.title
          }, null, 8, Kr)) : J("", !0)
        ])) : J("", !0)
      ]),
      Se(i.$slots, "content", {}, () => [
        Be("div", Xr, [
          i.step.content ? (F(), I("div", {
            key: 0,
            innerHTML: i.step.content
          }, null, 8, zr)) : (F(), I("div", Qr, "props is a demo step! The id of props step is " + re(Ze(s)) + " and it targets " + re(i.step.target) + ".", 1))
        ])
      ]),
      Se(i.$slots, "actions", {}, () => {
        var c, m, x, S;
        return [
          Be("div", Jr, [
            !i.isLast && w("buttonSkip") ? (F(), I("button", {
              key: 0,
              onClick: Oe(o, ["prevent"]),
              class: "v-step__button v-step__button-skip"
            }, re((c = i.labels) == null ? void 0 : c.buttonSkip), 1)) : J("", !0),
            !i.isFirst && w("buttonPrevious") ? (F(), I("button", {
              key: 1,
              onClick: v[0] || (v[0] = Oe(
                //@ts-ignore
                (...E) => i.previousStep && i.previousStep(...E),
                ["prevent"]
              )),
              class: "v-step__button v-step__button-previous"
            }, re((m = i.labels) == null ? void 0 : m.buttonPrevious), 1)) : J("", !0),
            !i.isLast && w("buttonNext") ? (F(), I("button", {
              key: 2,
              onClick: v[1] || (v[1] = Oe(
                //@ts-ignore
                (...E) => i.nextStep && i.nextStep(...E),
                ["prevent"]
              )),
              class: "v-step__button v-step__button-next"
            }, re((x = i.labels) == null ? void 0 : x.buttonNext), 1)) : J("", !0),
            i.isLast && w("buttonStop") ? (F(), I("button", {
              key: 3,
              onClick: Oe(u, ["prevent"]),
              class: "v-step__button v-step__button-stop"
            }, re((S = i.labels) == null ? void 0 : S.buttonStop), 1)) : J("", !0)
          ])
        ];
      }),
      Be("div", {
        class: Je(["v-step__arrow", { "v-step__arrow--dark": i.step.header && i.step.header.title }]),
        "data-popper-arrow": ""
      }, null, 2)
    ], 10, Ur));
  }
}), tn = (t) => {
  t.config.globalProperties.$tours = {}, t.component("v-tour", Ht), t.component("v-step", Zr);
};
export {
  tn as default
};
