import { useSupabaseCookieRedirect } from "../composables/useSupabaseCookieRedirect.js";
import { defineNuxtPlugin, addRouteMiddleware, defineNuxtRouteMiddleware, useRuntimeConfig, navigateTo, useSupabaseSession } from "#imports";
export default defineNuxtPlugin({
  name: "auth-redirect",
  setup() {
    addRouteMiddleware(
      "global-auth",
      defineNuxtRouteMiddleware((to) => {
        const config = useRuntimeConfig().public.supabase;
        const { login, callback, include, exclude, cookieRedirect, saveRedirectToCookie } = config.redirectOptions;
        if (include && include.length > 0) {
          const isIncluded = include.some((path) => {
            const regex = new RegExp(`^${path.replace(/\*/g, ".*")}$`);
            return regex.test(to.path);
          });
          if (!isIncluded) {
            return;
          }
        }
        const isExcluded = [...exclude ?? [], login, callback]?.some((path) => {
          const regex = new RegExp(`^${path.replace(/\*/g, ".*")}$`);
          return regex.test(to.path);
        });
        if (isExcluded) return;
        const session = useSupabaseSession();
        if (!session.value) {
          if (cookieRedirect || saveRedirectToCookie) {
            const redirectInfo = useSupabaseCookieRedirect();
            redirectInfo.path.value = to.fullPath;
          }
          return navigateTo(login);
        }
      }),
      { global: true }
    );
  }
});
